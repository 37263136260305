import React,{useState} from 'react'

export default function TextForm(props) {
    const handleOnClick = ()=>{
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("converted to uppercase", "success")
    }
    const handleLoClick = ()=>{
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("converted to lowercase", "success")
    }
    const handleCoClick = () => {
        navigator.clipboard.writeText(text)
        props.showAlert("Text copied", "success")
    };
    const handleCuClick = () => {
        let newText = navigator.clipboard.writeText(text);
        setText(newText = '');
        props.showAlert("Text cut", "success")
    };
    const handleClClick = ()=>{
        let newText = '';
        setText(newText);
        props.showAlert("Text cleared", "success")
    }
    const handleSpClick = () => {
        let newText = text.split(/\s+/).join(' ');
        setText(newText);
        props.showAlert("Removed text space", "success")
    }
    
    const handleEmClick = () => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
        const emails = text.match(emailRegex) || [];
        const emailsText = emails.join(' ,\n');
        navigator.clipboard.writeText(emailsText)
        props.showAlert("Email copy from text", "success")
    }
    
    
    

    const handleOnChange = (e)=>{
        setText(e.target.value);
    }
    const [text, setText] = useState('');
  return (
    <>
    <div className='container my-3' style={{color: props.mode === 'dark' ? 'white' : 'black'}}>
    <h1>{props.heading}</h1>
<div className="mb-3">
  <textarea className="form-control" value={text} onChange={handleOnChange} style={{backgroundColor: props.mode === 'dark' ? '#13466e' : 'white',color: props.mode === 'dark' ? 'white' : 'black'}} id="myBox" rows="8"></textarea>
</div>
<button className="btn btn-primary mx-1 my-1" onClick={handleOnClick}>Convert to Uppercase</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleLoClick}>Convert to Lowercase</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleCoClick}>Copy Text</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleCuClick}>Cut Text</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleClClick}>Clear Text</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleSpClick}>Remove Spaces</button>
<button className="btn btn-primary mx-1 my-1" onClick={handleEmClick}>Copy Emails</button>
    </div>
    <div className="container my-3" style={{color: props.mode === 'dark' ? 'white' : 'black'}}>
        <h1>Your text summary</h1>
        <p>{text.split(" ").filter((e)=>{return e.length!==0}).length} words and {text.length} characters</p>
        <p>{0.008 * text.split(" ").filter((e)=>{return e.length!==0}).length} Minutes read</p>
        <h2>Preview</h2>
        <p>{text.length>0?text:"Enter text to preview here"}</p>
    </div>
    </>
  )
}
